<template>
  <div :class="className" :style="{height:height,width:width}"/>
</template>

<script>
import echarts from 'echarts'
import {GetTaskProgressChartData,GetDepartmentPostUserList,GetPeopleProgressChartData} from '../api/oa'
require('echarts/theme/macarons') // echarts theme
import resize from '../views/Dashboard/admin/components/mixins/resize'

const animationDuration = 3000

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '700px'
    },
    departid: {
        type: String,
        default: ''
    }
  },
  data() {
    return {
      chart: null,
      list: [],
      departusers: [],
      peoplecode: []
    }
  },
  mounted() {
        //父组件传递过来的附件数组
        var progress = [
            {
            name: '>10%'
            },
            {
            name: '>30%'
            },
            {
            name: '>50%'
            },
            {
            name: '>70%'
            },
            {
            name: '>90%'
            },
    
    ]
      this.$nextTick(() => {
        this.initChart()
    })
        let para = {
                deptId: this.departid,
                isAllNextDept:false,
            }
      GetDepartmentPostUserList(para).then(res => {
                // this.departusers = res.data.response.data;
                var valuelist = new Array()
                var maxlist = new Array()
                console.log("aaaaaaaaaaaaaaaaaaaaaaaaaa");
                console.log(res);
                res.data.response.data.forEach(item => {
                    let obj = {}
                    obj.name = item.UserNameClaim;
                    valuelist.push(obj);
                    this.peoplecode.push(item.UserId);
                    let otherpara = {
                    user: this.peoplecode
                }
                if(this.peoplecode.length == 1){
                   GetPeopleProgressChartData(this.peoplecode).then(res => {
                      var valuelist = new Array()
                      res.data.response.forEach(item => {
                          let obj = {}
                          obj.value = item;
                          valuelist.push(obj);
                      })
                      this.list = valuelist.map((item,index) => {
                          return {...item, ...progress[index]};
                      });
                      this.initChart()
                  })
                }     
                })
                for(var i = 0; i<valuelist.length; i++){
                    let obj = {}
                    obj.max = 2;
                    maxlist.push(obj);
                }
                
                this.departusers = valuelist.map((item,index) => {
                    return {...item, ...maxlist[index]};
                });
                this.initChart()
            });
       
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        radar: {
          radius: '66%',
          center: ['50%', '42%'],
          splitNumber: 8,
          name: {
            textStyle: {
                color: '#000000' // 文字颜色
            }
        },
          splitArea: {
            areaStyle: {
              color: 'rgba(127,95,132,.3)',
              opacity: 1,
              shadowBlur: 45,
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetX: 0,
              shadowOffsetY: 15
            }
          },
          indicator: this.departusers
        },
        legend: {
          left: 'center',
          bottom: '115',
          data: ['>10%', '>30%', '>50%','>70%','>90%'],
        },
        series: [{
          type: 'radar',
          symbolSize: 0,
          areaStyle: {
            normal: {
              shadowBlur: 13,
              shadowColor: 'rgba(0,0,0,.2)',
              shadowOffsetX: 0,
              shadowOffsetY: 10,
              opacity: 1
            }
          },
          data: this.list,
          animationDuration: animationDuration
        }]
      })
    },
     
  }
}
</script>

<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import echarts from 'echarts'
import {GetPeopleComplatedTaskNumberGroupByTimeChartData} from '../api/oa'
require('echarts/theme/macarons') // echarts theme
import resize from '../views/Dashboard/admin/components/mixins/resize'

const animationDuration = 2000

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '330px'
    },
    departid: {
       type: Array,
       default: []
    }
  },
  data() {
    return {
      year:2020,
      chart: null,
      departusers: [],
      peoplecode: [],
      listone: [],
      listtwo: [],
      listthree: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
    var yearVal=this.$parent.$parent.getYear();
    // console.log(yearVal)
    this.year=yearVal;
    //console.log(this.year);
      var valuelist = new Array()
      this.departid.forEach(item => {
      valuelist.push(item.name);
      this.peoplecode.push(item.id);
      if(this.peoplecode.length == 1){
          GetPeopleComplatedTaskNumberGroupByTimeChartData({peoples:this.peoplecode,year:this.year}).then(res => {
            this.listone = res.data.response[0];
            this.listtwo = res.data.response[1];
            this.listthree = res.data.response[2];
            this.initChart()
        })
      }     
    })
    this.departusers = valuelist;
    this.initChart()
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')

      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: 10,
          left: '2%',
          right: '2%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: this.departusers,
          axisTick: {
            alignWithLabel: true
          },
          axisLabel:{
            interval: 0,
           },
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          }
        }],
        series: [{
          name: '本日',
          type: 'bar',
          stack: 'vistors',
          barWidth : 40,
          data: this.listone,
          animationDuration
        }, {
          name: '本周',
          type: 'bar',
          stack: 'vistors',
          barWidth : 40,
          data: this.listtwo,
          animationDuration
        }, {
          name: '本月',
          type: 'bar',
          stack: 'vistors',
          barWidth : 40,
          data: this.listthree,
          animationDuration
        }]
      })
    }
  }
}
</script>
